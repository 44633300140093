import type { ErrorResponse } from 'react-router';
import { href, Link, Links, Meta } from 'react-router';

export type Page404Props = { error: ErrorResponse };

export function Page404({ error }: Page404Props) {
  const data = JSON.parse(error.data || '{}');
  const title = `${error.status} - ${error.statusText} | Shape Up Meal Prep`;

  return (
    <html lang='en' className='h-full'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <meta name='apple-mobile-web-app-title' content='Shape Up Meal Prep' />
        <meta name='application-name' content='Shape Up Meal Prep' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />

        <title>{title}</title>
        <Meta />

        {/* Favicon icon images */}
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />

        <Links />
      </head>

      <body className='h-full'>
        <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
          <div className='text-center'>
            <p className='text-base font-semibold text-brand-600'>
              {error.status}
            </p>

            <h1 className='mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl'>
              {error.statusText}
            </h1>

            <p className='mt-6 text-base leading-7 text-neutral-600'>
              {data.statusTextFriendly}
            </p>

            <div className='mt-10 flex items-center justify-center gap-x-6'>
              <Link
                to={href('/')}
                className='rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600'>
                Go back home
              </Link>

              <Link
                to={href('/contact')}
                className='text-sm font-semibold text-neutral-900'>
                Contact us <span aria-hidden='true'>&rarr;</span>
              </Link>
            </div>
          </div>
        </main>
      </body>
    </html>
  );
}
